import { Injectable } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user.service';
import {MsalService} from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private jwt: JwtHelperService,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService,
    private msalService: MsalService
  ) { }

  isAuthenticated() {
    const token = localStorage.getItem('impact-token');
    return !this.jwt.isTokenExpired(token);
  }

  login(user) {
    console.log('login', user);
    return this.http.post(environment.apiUrl + 'auth/login', user);
  }

  logout() {
    localStorage.setItem('logout-in-progress', 'true');
    sessionStorage.setItem('recent-logout', 'true');

    const msalAccount = this.msalService.instance.getActiveAccount();
    const logoutPromises = [];

    logoutPromises.push(this.http.post(environment.apiUrl + 'auth/logout', {}).toPromise());

    Promise.all(logoutPromises).then(() => {
      this.logoutTasks();
    }).catch(() => {
      this.toastr.error(this.translate.instant('Cannot logout'));
    }).finally(() => {
      if (msalAccount) {
        this.msalService.instance.setActiveAccount(null);
        // Use logoutRedirect instead of logoutPopup for more reliable logout
        this.msalService.logoutRedirect({
          postLogoutRedirectUri: environment.oauthData.microsoftOauth.logoutRedirectUri
        });
      } else {
        this.router.navigateByUrl('/login');
        localStorage.removeItem('logout-in-progress');
      }
    });
  }

  private logoutTasks(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.userService.authUser = null;
    window.dispatchEvent(new CustomEvent('logout'));
  }
}
